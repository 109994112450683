import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 查询设备零件列表
export function listComponent(query: any) {
  return request({
    url: "/factory/componentType/list",
    method: "get",
    params: query,
  });
}

// 查询设备零件详细
export function getComponent(query: any) {
  return request({
    url: "/factory/componentType/" + query.id,
    method: "get",
    params: query,
  });
}

// 新增设备零件
export function addComponent(data: any) {
  return request({
    url: "/factory/componentType",
    method: "post",
    data: data,
  });
}

// 修改设备零件
export function updateComponent(data: any) {
  return request({
    url: "/factory/componentType",
    method: "put",
    data: data,
  });
}

// 删除设备零件
export function delComponent(data: any) {
  return request({
    url: "/factory/componentType/removes",
    method: "delete",
    data: data,
  });
}
